import React from "react";
import { Link } from "gatsby";
import { Layout, SEO } from '../../../components/structure';
import { Content } from "../../../components/blocks"
import styled from "styled-components";
import { brand, font, mq } from "../../../styles";

const Header = styled.div`


${ font.imports.sherika }

  width: 90%;
  max-width: 1100px;
  text-align: left;
  padding-top 80px;

  h2 {
    font-family: ${font.family.sherika};
    font-size: 56px;
    font-weight: 700;
    padding: 20px 0;
    color: ${brand.colors.pg_primary_dark};
    display: inline-block;

    .color-emphasis {
      color: ${brand.colors.pg_orange};
    }
  }

  span {
    display: inline-block;
    color: ${brand.colors.pg_primary_dark};
  }

  .date {
    display: block;
    font-family: ${font.family.sherika};
    font-weight: 700;
    font-size: 16px;
    margin: 30px 0;
  }

  p {
    color: ${brand.colors.pg_primary_dark};
    font-size: 14px;
    line-height: 21px;

    a {
      display: inline-block;
      color: ${brand.colors.pg_blue};
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const Subsection = styled.div`
  width: 90%;
  max-width: 1100px;
  text-align: left;
  padding-bottom: 40px;
  .date {
    display: block;
    font-family: ${font.family.sherika};
    font-weight: 700;
    font-size: 16px;
    margin: 30px 0;
  }
  h5 {
    font-size: 24px;
    font-weight: 600;
    color: ${brand.colors.pg_orange};
    padding: 20px 0;

    &:last-of-type {
      padding-bottom: 10px;
      margin-bottom: 0;
    }
  }

  p {
    color: ${brand.colors.pg_primary_dark};
    font-size: 16px;
    line-height: 21px;

    a {
      display: block;
      color: ${brand.colors.pg_blue};
      cursor: pointer;
      text-decoration: underline;
    }
  }

  ul {
    li {
      list-style-type: disc;
      color: ${brand.colors.pg_primary_dark};
      a {
        display: block;
        color: ${brand.colors.pg_blue};
        cursor: pointer;
        text-decoration: underline;
        font-size: 16px;
        line-height: 21px;
      }
    }
  }
`;

const TableDetails = styled.table`
display: block;
  color: ${brand.colors.pg_primary_dark};
  font-family: ${font.family.sherika};
  font-size: 20px;
    thead
      tr {
        td {
          color: ${brand.colors.pg_primary_dark};
          border-top: 1px solid hsla(0,0%,0%,0.12);
          font-weight: 600;
        }
    }

    tbody {
      tr {
        td {
          vertical-align: top;
          font-weight: 400;

          a {
            color: ${brand.colors.pg_orange};
            text-decoration: underline;
          }
        }
        td:first-of-type {
          font-weight: 700;
        }
        
      }
    }

    @media only screen and ${mq.maxMd} {
      display: none;
    }
`;

const TableDetailsMobile = styled.div`
    display: none;

    div {
      margin: 20px 0;
      span {
        color: ${brand.colors.pg_primary_dark};
        font-family: ${font.family.sherika};
        font-size: 20px;
        display: block;
        margin: 10px 0;
      }

      .purpose-con, .optout-con {
        font-family: ${font.family.sherika};
        font-size: 16px;

        a {
          text-decoration: underline;
          color: ${brand.colors.pg_orange};
        }
      }
    }

    @media only screen and ${mq.maxMd} {
      display: block;
    }
`;

const CookiePolicy = styled.div`
    width: 95%;
    max-width: 1100px;
    margin: auto;
`

const GDPRPage = () => (
  <Layout>
    <SEO title="Cookie Policy | Plusgrade" 
          description={'Plusgrade Cookie Policy'}
    />
    <Content whiteBG e2e>
      <CookiePolicy>
        <Header>
          {/* <h2>GENERAL TERMS AND CONDITIONS <span className={"color-emphasis"}>Cookie Policy</span></h2> */}
          <h2>GENERAL TERMS AND CONDITIONS </h2> 
          {/* <span className={"date"}>Last Updated May 24, 2018</span> */}

          <p>
          These General Terms and Conditions govern the use of the Services of Plusgrade ("Plusgrade"). The
Purchase Order submitted to Plusgrade (the "PO"), using a Purchase Order document or Plusgrade's
on-line subscription, will specify the client's identity and details ("Client"), the Services selected,
Plusgrade's fees and charges (the "Fees"), the term of the agreement between the parties (the
"Term") and other details of the Services. The PO and these General Terms and Conditions will
constitute the complete agreement between Plusgrade and the Client (the "Agreement"). Execution
of the PO or use of the Services by the Client will conclusively indicate Client's acceptance of these
General Terms and Conditions. If there is any conflict or inconsistency between these General
Terms and Conditions and the PO, the PO shall prevail.
</p>

        </Header>
        <Subsection>
          <ol style={{color: '#F74E01'}}>
            <li>
              <h5>The Service</h5>
              <p>Plusgrade provides a Software-as-a-Service platform which connects to a hotel reservations system
and automatically offers the hotel's current and prospective guests (the "Guests") to enhance their
stay by various means, including upgrade bids, as described at <a href="https://www.plusgrade.com">https://www.plusgrade.com</a> the
“Services"). The Service is provided on a subscription basis for the term specified in the PO and as
set forth under the Section Captioned "Term and Termination" below (the "Subscription"). Client
undertakes that the Service shall be integrated and used for at least the minimum number of
Client's hotels as set forth in the PO.
</p>
            </li>
            <li>
              <h5>Authorized Representative</h5>
              <p>You are accepting these Terms and Conditions on behalf of the Client, which is your employer or
another entity you are affiliated with, and therefore you represent and warrant that: (i) you have full
legal authority to bind the Client to these Terms and Conditions; (ii) you have read and understood
this Agreement; and (iii) you agree, on behalf of the Client to this Agreement. If you don't have the
legal authority to bind the Client, please do not accept these Terms and Conditions (or, if
applicable, do not sign the PO).
</p>
            </li>
            <li>
              <h5>Fees and Payments</h5>
              <p>In consideration for the Service, Client shall pay Plusgrade the applicable fees as set forth in the
applicable PO, which are based on a revenue-share model (the “Fees”). Unless indicated otherwise,
Fees are stated in US dollars. Client hereby authorizes Plusgrade, either directly or through Plusgrade's
payment processor, to charge such Fees via the selected payment method, upon the due date.
Unless expressly set forth herein, the Fees are non-cancelable and non-refundable. Plusgrade
reserves the right to change the Fees at any time, upon notice to Client, provided that such change
may only apply upon the earlier of (i) the lapse of 12 months following the date of the notice, or (ii)
following the Subscription Term. Client is solely responsible for billing Guests and collecting
payments, and shall act, in this regard, in a diligent and effective manner.
</p>
<p>All Fees charged hereunder are, except where otherwise explicitly stated, exclusive of all taxes.
Client shall bear and be responsible for all applicable taxes and other governmental charges
imposed on it with respect to this Agreement, if any. Client's payment obligation is not subject to
any lien, withholding or set off. Failure to timely pay any Fees due is grounds for termination or
suspension of the Services, without prejudice to other remedies
.</p>
<p>Client shall pay each invoice according to the payment terms set forth in the PO. If Plusgrade has not
received payment of any sums within ten (10) days after the due date and does not receive
payment within a further period of ten (10) days from the date of written notification of
non-payment, then without prejudice to any other rights and remedies of Plusgrade, Plusgrade may
suspend the access to the Services, suspend any of the Services, and/or terminate this Agreement.
Furthermore, if the Client fails to make a payment when due of any sum payable under this
Agreement, not as a result of a dispute in good-faith, such payment shall bear interest from the date
when such payment was due until the date of actual payment at a rate equal to 0.5% per month or
the maximum interest rate allowed by applicable law, whichever is less, without derogating from
any right or remedy of Plusgrade under any applicable law. Client will be responsible for all reasonable
expenses (including attorneys' fees) incurred by Plusgrade in collecting such delinquent amounts.
</p>
            </li>

            <li>
              <h5>Intellectual Property and License</h5>
              <p>All right, title and interest in the Services and any materials provided by Plusgrade to the Client, and
any development or derivative thereof, including without limitation, all copyrights, trade secrets, and
other intellectual property rights pertaining thereto shall remain vested in Plusgrade and its licensors
and this Agreement does not convey to Client any interest in or to the Services, except for a limited
right of use as set forth herein, terminable in accordance with the terms of the Agreement.
Subject to the Client's compliance with the Agreement, and during the Subscription Term, Plusgrade
hereby grants Client a limited, non-transferable, non-exclusive, non-assignable, non-sub-licensable,
personal license to use the Services for Client's own hotels. Except as expressly provided herein,
no other rights or licenses, expressed or implied, are granted to Client by Plusgrade with respect to
the Services, including any proprietary information, patent, copyright, trademark, trade secret, or
other intellectual property right, whether registered or not.
All right, title and interest in the Services and any materials provided by Plusgrade to Client, and any
development, improvement or derivative thereof, including without limitation, all copyrights, trade
secrets, and other intellectual property rights pertaining thereto shall remain vested in Plusgrade and
its licensors and these Terms and Conditions do not convey to Client any interest in or to the
Services, except for a limited right of use as set forth herein, terminable in accordance with these
Terms and Conditions.
</p>
<p>
Client may not, and may not permit or aid others to: (i) use the Services for any purpose other than
the purpose explicitly set forth herein; (ii) copy, alter, translate, emulate, create derivative works
based on, or reproduce the Services; (iii) modify, publish, sell, distribute, assign, pledge or transfer
(by any means), display, sublicense, rent, lease or otherwise share the rights granted under this
Agreement; (iv) reverse engineer, de-compile, modify, revise or disassemble the Services or any
part thereof, or create derivative works thereof, or extract source code from the object code of the
Services, or access the Services in order to build a competing product or service; (v) use the
Services in any manner that is illegal or not authorized by this Agreement; (vi) grant access to the
Services to third parties or attempt to do so or purport to be entitled to do so; (vii) engage in any
activity that interferes with or disrupts the Service (or the servers and networks which are related to
the Service); (viii) take any action that imposes or may impose (as determined by Plusgrade in its sole
discretion) an unreasonable or disproportionately large load on Plusgrade's (or its third party
providers’) infrastructure; (ix) bypass any measures Plusgrade may use to prevent or restrict access to
the Service and/or take any action intended to circumvent or disable the operation of any security
feature or measure of the Service; (x) provide any information if Client does not own or have
permission to use such information; (xi) access the Service via any means other than through the
interface provided by Plusgrade, or via automated means, including by crawling, scraping, caching or
otherwise; (xii) disrupt the use of other users or otherwise act in a manner that negatively affects
other users’ ability to engage using the Service.
</p>
<p>All photos, texts, scripts, designs, graphics, logos, audios, videos, songs, interactive features,
trademarks, service marks, trade names and other content (“Content”) used, displayed, included,
incorporated, uploaded, posted or published by Plusgrade or other third parties, as part of the Service
(other than Client Content (as such term is defined below)) are the sole property of Plusgrade and/or
its licensors (“Plusgrade Content”), subject to copyright and other intellectual property rights under
applicable laws, and Client may not use, download, distribute and/or copy them, in whole or in
part, without the prior written permission of Plusgrade or any relevant third party. If Client provides
Plusgrade with feedback or suggestions regarding the Services, such feedback and suggestions shall
become Plusgrade's property and Plusgrade may use them at its sole discretion, without any obligation
to compensate Client in any manner.
</p>
<p>Plusgrade may collect, use and publish Anonymous Information (defined below), and disclose it to its
third party service providers, inter alia to provide, develop, improve and publicize the Plusgrade 's
Service. Client hereby grants Plusgrade full access to such Anonymous Information. “Anonymous
Information” means information about use of the Service which does not enable identification of an
individual, such as meta-data or aggregated or analytic information about use of the Service.
</p>
            </li>

            <li>
              <h5>Third Party Materials</h5>
              <p>The Services may contain software or other materials provided by third parties (including "open
source" software) ("Third Party Materials"). Use of such Third Party Materials shall be governed
by the license agreements specific to each such Third Party Material, provided, however, that in
addition to any terms and conditions of any third party software license as set forth in the
abovementioned webpage, and without derogating from such terms, the disclaimer of warranty and
limitation of liability set forth herein shall apply to all software. If the Services contains any software
provided by third parties not noted in the abovementioned webpage, the restrictions contained in
this Agreement shall apply to all such third party software providers and third party software as if
they were Plusgrade and the Services, respectively. By using the Services Client agrees to the
licenses that apply to any Third Party Materials.
</p>
<p>Prior to installing any part of the Services, Client may be required to install additional third party
software, if such third party software is not already installed on Client's hardware or devices. Client
shall bear all costs and expenses associated with such third party software, and shall directly
purchase that software from the vendor. This third party software is separate and independent of
the Services. The use of such software is not governed by this Agreement, and shall be governed
only by the license agreements specific to such software, to which Plusgrade is not a party. No legal
relationship shall be formed between Plusgrade and Client with respect to such third party software.
IN NO EVENT SHALL PLUSGRADE BE LIABLE FOR ANY DAMAGE CAUSED BY SUCH SEPARATE AND
INDEPENDENT THIRD PARTY SOFTWARE. SUCH SOFTWARE IS PROVIDED "AS IS", WITHOUT
ANY WARRANTY BY PLUSGRADE.
</p>
            </li>

            <li>
              <h5>Interaction with Guests</h5>
              <p>Client shall be responsible and liable that any communication with the Guest and any action taken
in connection with such communication, such as orders submitted by Guests and accepted by
Client or requests for cancelation by Guests, shall be in full compliance with the terms of the
Agreement and all applicable laws and regulations. Client shall ensure and shall be fully responsible
and liable that the Guests gave their explicit consent so that Plusgrade may lawfully use, process and
transfer any information related to Guests and contact the Guests in connection with the Service
(including, without limitation by email and SMS messages), as required by any and all laws,
regulations and industry guidelines applicable to the processing of personal information and
delivery of commercial offers and other communication. Client shall comply and shall ensure
compliance with all laws, regulatory requirements or codes or practice governing the use, storage
or transmission of such personal information and communication with the Guests, including,
without limitation, privacy and anti-spam laws and regulations. Such compliance is the Client's sole
responsibility and liability, and Client represents, warrants and undertakes that there is no
prohibition or limitation under applicable laws or other regulatory requirements to use the Service. It
is hereby clarified that the Service is based on email, SMS and other communication with the
Guests and Client is responsible and liable for all legal aspects of such communication. In addition,
Client shall ensure and shall be fully responsible and liable for compliance with any applicable
consumer protection laws and regulations, including, without limitation, with respect to any order
made by a Guest in connection with the Service and the cancellation thereof.
</p>
            </li>


            <li>
              <h5>Client Infrastructure</h5>
              <p>Client is solely responsible for obtaining, maintaining and operating Client's reservations system
and any related system, equipment, hardware, software, backup systems, security systems and
measures or ancillary services (the "Client's Infrastructure"). Client shall be solely responsible and
liable for any fees charged by third parties in connection with the Client's Infrastructure. Client's
Infrastructure shall, at minimum, meet the specifications set forth in the PO. Client shall be solely
responsible for procuring and maintaining its network connections and telecommunications links,
and for all problems, conditions, delays, delivery failures and all other loss or damage arising from
or relating to the Client's network connections or telecommunications links or caused by the
internet. Plusgrade shall have no responsibility or obligation in connection with the above. Without
limiting the generality of the aforementioned, Plusgrade is not responsible for any delays, delivery
failures, or any other loss or damage resulting from the storage or transfer of data over
communications networks and facilities, including the internet, and to the performance of any
third-party communication, hosting or storage providers, and the Client acknowledges that the
Services may be subject to interruptions, limitations, delays and other risks associated with the use
of such storage, hosting and communications facilities.
</p>
<p>Client is solely responsible for responding to any claims, requests and demands by the Guests or
any other third party. Plusgrade will, to the extent allowed by law, promptly notify Client of its receipt
of a Guest’s or such related third party claim, request or demand and comply with Client's
reasonable requests regarding the handling to such claim, request or demand. Client is responsible
for all acts or omissions of Guests.
</p>
            </li>

            <li>
              <h5>Client Content</h5>
              <p>While using the Services, Client may provide certain content to Plusgrade, such as images and texts
(“Client Content”). Client shall be solely responsible and liable for any Client Content and for any
damage or loss resulting therefrom, and shall comply in all respects with any and all applicable
laws, regulations and industry guidelines applicable to the Client Content. Plusgrade shall have no
right in the Client Content, except for the minimal rights required in order to facilitate the use of the
Services and shall not use the Client Content for any other purpose. Client shall indemnify and hold
harmless Plusgrade or anyone on its behalf from any liability, cost, damage and expense (including
reasonable legal fees) caused in connection with the Client Content.
</p>
            </li>

            <li>
              <h5>Confidentiality</h5>
              <p>Each Party (“Receiving Party”) shall keep confidential and shall not disclose to any third party any
Confidential Information (as such term is defined below) which it has acquired from the other Party
(“Disclosing Party”) and shall only use such Confidential Information for purposes in connection
with this Agreement. Such confidentiality obligation shall continue after the termination of the
Agreement.
</p>
<p>Client acknowledges that the Services contains confidential and proprietary information and trade
secrets of Plusgrade and agrees to keep the Services confidential. Client further acknowledges that
any unauthorized disclosure, use, or copying of the Services may cause Plusgrade serious financial
loss.
</p>
<p>"Confidential Information" means any know-how, any trade or business secrets, any commercial,
financial, business, technical or other confidential information of whatever nature relating to the
business of a Party (whether written, oral or in electronic or other form) including this Agreement,
which is clearly designated by a Party as being confidential (whether or not it is marked as
confidential) or of clear confidential nature. Confidential Information shall not include information
that: (i) is or becomes publicly known other than through any act or omission of the Receiving Party;
(ii) was in the Receiving Party’s lawful possession before the disclosure; (iii) is lawfully disclosed to
the Receiving Party by a third party without restriction on disclosure; (iv) is independently
developed by the Receiving Party without use of Confidential Information of the Disclosing Party,
which independent development can be shown by written evidence; or (v) is required to be
disclosed by law, by any court of competent jurisdiction or by any regulatory or administrative body.
</p>
            </li>

            <li>
              <h5>Term and Termination</h5>
              <p>The Agreement shall be in effect for the subscription term set forth in the PO (the “Initial Term”)
and shall automatically renew for additional periods equal to the original initial term specified in the
PO, unless either Party gives the other a sixty (60) day notice of non-renewal before the end of the
relevant subscription term (each a “Renewal Term”, and together with the Initial Term, the
“Subscription Term”).
</p>
<p>Either Party may terminate this Agreement with immediate effect if the other Party: (i) materially
breaches this Agreement and such breach remains uncured (to the extent that the breach can be
cured) fifteen (15) days after having received written notice thereof; (ii) becomes liquidated,
dissolved, bankrupt or insolvent, whether voluntarily or involuntarily, or shall take any action to be
so declared, the other Party shall have the right to immediately terminate this Agreement.
If Plusgrade believes that Client is using the Service in a manner that violates laws, rules or
regulations or creates an excessive burden or potential adverse impact on Plusgrade, its partners or
its suppliers, Plusgrade may terminate this Agreement immediately or suspend Client’s access to and
use of the Service until such time as Plusgrade believes the issue has been resolved.
Upon termination of this Agreement, Client shall cease all access to and use of the Service, and
shall not be able to access any data stored within the Service.
Termination of this Agreement for any reason shall not relieve Client from its obligation to pay any
outstanding payments due under this Agreement.
Sections 4, 9, 13, 17 and 20 shall survive termination of this Agreement.
</p>
            </li>

            <li>
              <h5>
              Security
              </h5>

              <p>Plusgrade cares about the security of the Services, and therefore it uses technical and organizational
measures designed to secure the Client's data from accidental loss and from unauthorized access,
use, alteration or disclosure. However, there is always risk involved in electronic transmission and
storage of information and allowing access to electronic systems. As a result, Plusgrade cannot fully
ensure or warrant the absolute security and privacy of the Client's data, and Client acknowledges
and agrees to these reservations.
</p>
            </li>

            <li>
              <h5>Warranty and Disclaimer</h5>
              <p>Plusgrade warrants that during the Term, and subject to the Client's compliance with this Agreement,
in the event that the Services shall contain any material errors which will prevent the ordinary use of
the Services, Plusgrade shall make reasonable commercial efforts, either to repair or replace such
erroneous Services, for no additional cost (in its sole discretion). Plusgrade is not obligated to fix
errors that are immaterial. Immaterial errors are those that do not prevent the ordinary use of the
Services as determined by Plusgrade in its sole discretion. Plusgrade does not warrant that the Services
will be error free or secure, that the use of the Services will be uninterrupted or that it shall meet
Client's requirements. This warranty shall not apply if the Service is used on or in conjunction with
hardware or software other than the unmodified version of hardware and Services with which the
Services was designed to be used as described in the PO. Client's sole remedy in case of breach of
the aforementioned warranty is termination of the Agreement, and no other remedy will apply. n
case of a claim of a third party that normal use of the unmodified Services by Client violates or
infringes intellectual party rights of such third party, or if Plusgrade believes that the Service, or any
part thereof, may so infringe, Plusgrade will, at its sole discretion, either (i) procure a license from such
third party, (ii) modify the Services to prevent infringement, or (iii) terminate this Agreement and
issue to Client a refund for any payments made by Client in respect of the post-termination period.
No other rights or remedies will accrue against Plusgrade.
</p>
<p>THIS SECTION STATES PLUSGRADE’S SOLE AND ENTIRE LIABILITY AND YOUR AND YOUR
EXCLUSIVE REMEDY, FOR ANY INTELLECTUAL PROPERTY INFRINGEMENT OR
MISAPPROPRIATION BY PLUSGRADE AND/OR ITS SERVICES AND UNDERLYING TECHNOLOGY.
EXCEPT FOR THE WARRANTY SET FORTH ABOVE, CLIENT EXPRESSLY ACKNOWLEDGES AND
AGREES THAT USE OF THE SOFTWARE IS AT CLIENT'S SOLE RISK AND THAT THE ENTIRE RISK
AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND RESULTS IS SOLELY WITH
CLIENT. THE SERVICES ARE PROVIDED “AS IS, AS AVAILABLE”, AND WITHOUT WARRANTIES,
GUARANTEES OR REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
STATUTORY, COMMON LAW OR OTHERWISE. PLUSGRADE DOES NOT WARRANT THAT THE USE OF
THE SERVICE WILL BE UNINTERRUPTED, ERROR-FREE OR WILL MEET CLIENT'S SPECIFIC
REQUIREMENTS. PLUSGRADE MAKES NO WARRANTY OR REPRESENTATION, EITHER EXPRESS OR
IMPLIED, REGARDING THE SERVICE, THE PLUSGRADE CONTENT AND THE USE THEREOF,
INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR
FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, AVAILABILITY, SECURITY,
COMPATIBILITY, NON-INFRINGEMENT OR COMPLETENESS OF RESPONSES, RESULTS AND
LACK OF NEGLIGENCE.
</p>
            </li>

            <li>
              <h5>Limitation of Liability</h5>
              <p>IN NO EVENT SHALL PLUSGRADE, ITS SHAREHOLDERS, DIRECTORS, OFFICERS, AGENTS OR
EMPLOYEES BE LIABLE, UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY, OR OTHER
LEGAL OR EQUITABLE THEORY, FOR ANY SPECIAL, INCIDENTAL, PUNITIVE, CONSEQUENTIAL
OR INDIRECT DAMAGES, INCLUDING LABOR COSTS, LOSS OR CORRUPTION OF DATA OR
INFORMATION, LOSS OF GOODWILL, LOSS OF PROFITS, LOSS OF SAVINGS, LOSS OF
BUSINESS INFORMATION, PURE ECONOMIC LOSS, OR LOSS OF USE OR OTHER PECUNIARY
LOSS, IN CONNECTION WITH OR ARISING OUT OF THIS AGREEMENT, THE SERVICE OR
CLIENT'S USE OF OR INABILITY TO USE THE SERVICE, HOWEVER CAUSED, ON ANY THEORY
OF LIABILITY (WHETHER BASED ON BREACH OF CONTRACT, WARRANTY, TORT, NEGLIGENCE,
EQUITY, PRODUCT LIABILITY, OR OTHERWISE), AND REGARDLESS OF WHETHER PLUSGRADE HAS
BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR SUCH DAMAGES WERE
REASONABLY FORESEEABLE.<br></br>
IN NO CASE SHALL THE AGGREGATE LIABILITY OF PLUSGRADE AND ITS SHAREHOLDERS,
DIRECTORS, OFFICERS AND EMPLOYEES UNDER THIS AGREEMENT OR ARISING OUT OF OR
OTHERWISE RELATED TO CLIENT'S USE OF THE SERVICE EXCEED THE CONSIDERATION
CLIENT ACTUALLY PAID TO PLUSGRADE FOR THE SERVICE IN THE THREE MONTHS PRECEDING
THE CAUSE OF THE CLAIM.<br></br>
WITHOUT DEROGATING FROM THE FOREGOING, PLUSGRADE ASSUMES NO LIABILITY OR
RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF PLUSGRADE CONTENT,
(II) ANY UNAUTHORIZED ACCESS TO OR USE OF CLIENT'S SERVERS OR DEVICES AND ANY
INFORMATION STORED THEREIN, (III) ANY INTERRUPTION OR CESSATION OF TRANSMISSION,
LOSS OR LEAK OF DATA OR OTHER COMMUNICATIONS (IV) ANY BUGS, VIRUSES, TROJAN
HORSES, OR THE LIKE, INCLUDING VIRUSES AND TROJAN HORSES WHICH MAY BE
TRANSMITTED TO OR THROUGH THE SOFTWARE THROUGH THE ACTIONS OF ANY THIRD
PARTY, AND/OR (V) ANY ERRORS OR OMISSIONS IN ANY PLUSGRADE CONTENT OR ANY LOSS OR
DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICE.
Nothing in this Agreement is intended to exclude liability that cannot be excluded under applicable
law (only to the extent such liability cannot be excluded).
</p>
            </li>

            <li>
              <h5>Export Controls</h5>
              <p>Client must comply with all applicable export control laws, rules and regulations. Client agrees that
Services will not be shipped, transferred, or exported into any country or used in any manner
prohibited by law, including, without limitation, Cuba, Iran, Syria, North Korea, Lebanon, or the
Crimea region of Ukraine. Client acknowledges that the Services may contain cryptographic
features and is subject to international and local country laws governing import, export, distribution
and use of cryptography. In particular, but without limitation, the Services may not be exported or
re-exported (a) into any U.S. or other embargoed countries or (b) to anyone on the U.S. Treasury
Department's list of Specially Designated Nationals or the U.S. Department of Commerce Denied
Person’s List or Entity List or any other restricted party lists of the United States. By using the
Services, Client represent and warrant that Client is not located in any such country or on any such
List.
</p>
            </li>

            <li>
              <h5>Advertisement</h5>
              <p>The Client agrees that Plusgrade will be entitled to use the Client’s name and logo for its marketing.</p>
            </li>


            <li>
              <h5>Force Majeure</h5>
              <p>Without derogating from any other provision limiting the responsibilities of a Party, neither Party
(“Affected Party”) shall have any liability to the other Party under this Agreement if it is prevented
from or delayed in performing its obligations under this Agreement, or from carrying on its business,
by acts, events, omissions or accidents beyond its reasonable control, including, without limitation,
strikes, lock-outs or other industrial disputes (excluding those involving the workforce of the Party
seeking to rely on this clause), failure of a utility service or transport or telecommunications
network, act of God, war, riot, pandemic, civil commotion, malicious damage, compliance with any
law or governmental order, rule, regulation or direction, accident, breakdown of plant or machinery,
fire, flood or storm (“Force Majeure Event”), provided that the other Party is notified of such an
event and its expected duration. The Affected Party shall: (i) carry out its duties to the best level
reasonably achievable in the circumstances of the Force Majeure Event; (ii) take all commercially
reasonable steps to overcome and mitigate the effects of the Force Majeure Event as soon as
reasonably practicable; and (iii) notify the other party when the Force Majeure Event has stopped. If
the Force Majeure Event prevents, hinders or delays the Affected Party’s performance of its
obligations for a continuous period of more than thirty (30) days the other Party may terminate this
Agreement immediately on written notice to the Affected Party.
</p>
            </li>


            <li>
              <h5>Privacy and Data Protection</h5>
              <p>Client acknowledges and agrees that Client's use of the Service, including, without limitation, any
information transmitted to or stored by Plusgrade, is governed by the Plusgrade Privacy Policy at <a href="https://www.plusgrade.com/policy/privacy/">https://www.plusgrade.com/policy/privacy/</a> and the Data Processing Addendum available at: <a href="http://www.plusgrade.com/policy/hospitality/dpa">http://www.plusgrade.com/policy/hospitality/dpa</a> which are included in these Terms and Conditions by reference.
</p>
            </li>

            <li>
              <h5>Indemnification</h5>
              <p>Without derogating from Plusgrade's rights under this Agreement or under applicable law, Client shall
indemnify and hold harmless Plusgrade and its subsidiaries, affiliates, directors, officers, employees,
agents, and personnel against any losses, liabilities, damages, and expenses to the extent arising
out of or related to (i) claims of Guests or any other third party related to the Client; (ii) claims that
use of the Client Content and any other content provided by Client infringes any intellectual
property right or other right of a third party.
</p>
            </li>

            <li>
              <h5>Parties' Relationship; Third Parties</h5>
              <p>The Agreement does not, and shall not be construed to create a joint venture, agency relationship,
employment or partnership between the Parties. The Agreement does not confer any rights on any
person or party other than Plusgrade and the Client.
</p>
            </li>


            <li>
              <h5>General</h5>
              <p>Each of the parties acknowledges and agrees that in entering into this Agreement it does not rely
on any undertaking, promise, assurance, statement, representation, warranty or understanding
(whether in writing or not) of any person (whether a party to this Agreement or not) relating to the
subject matter of this Agreement, other than as expressly set out in this Agreement. This Agreement
shall be governed by and construed in accordance with the laws of the State of New York
(excluding provisions of conflict of laws) and any controversy, claim or dispute between the Parties,
concerning this Agreement shall be finally settled by arbitration to be held in New York city, New
York in accordance with the Rules of the International Chamber of Commerce. The arbitration will
be held in English.</p>
<p>This Agreement may only be modified by a written document which has been signed by both
parties. No failure of a Party to exercise, and no delay by it in exercising, any right, power or
remedy in connection with this Agreement will operate as a waiver of such right, power or remedy
nor will any single or partial exercise of any right, power or remedy preclude any other or further
exercise of such right, power or remedy or the exercise of any other rights, powers or remedies
(whether provided by law or otherwise). No waiver of any breach of this Agreement shall be
deemed to be a waiver of any subsequent breach.
<br/>If any provision (or part of a provision) of this Agreement is found by any court or administrative
body of competent jurisdiction to be invalid, unenforceable or illegal, the other provisions shall
remain in force, and such provision shall be reformed only to the extent necessary to make it valid,
enforceable and legal.
</p>
<p>Client may not assign this Agreement, and any rights or obligations hereunder, in whole or in part
without the prior written consent of Plusgrade, and any attempt by Client to so assign this Agreement
shall be deemed null and void. Plusgrade expressly reserves the right to assign this Agreement to an
acquirer of or successor to substantially all of its business assets related to this Agreement and/or
to the Services without Client's consent and without providing the Client any notice. The Client shall
not have any lien or set-off right against Plusgrade, and the Client hereby waives any such right.
There are no third-party beneficiaries to this Agreement.
</p>
            </li>

            <li>
              <h5>Notices</h5>
              <p>All notices, requests, demands, and other communications hereunder shall be in writing and
delivered in person, by mail, by recognized courier service, or by facsimile to the intended recipient
at its address specified in the PO. Either Party may from time to time change its address by giving
the other Party notice of the change in accordance with this section. Any notice served personally
shall be deemed to have been received on the day of service, any notice sent by registered mail
shall be deemed to have been received five business days after being posted (eight – if sent
internationally). Any notice sent by facsimile or email shall be deemed to have been received upon
receipt of confirmation of receipt, unless sent outside recipient's normal business hours, in which
case, it will be deemed received on the following business day.
</p>
            </li>
          </ol>

          <span className={"date"} style={{color: 'black'}}>Last Updated: July 23rd, 2023</span>
        </Subsection>
      </CookiePolicy>
    </Content>
  </Layout>
);




export default GDPRPage;
